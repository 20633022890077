<template>
  <div class="meny">
    <div class="logo">
      <a href="/">
        <img src="../assets/img/logo.png" />
      </a>
    </div>
    <div id="nav" class="overlay">
      <div class="lukk">
        <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()">&times;</a>
      </div>

      <nav class="overlay-content">
        <a v-on:click="closeNav()" href="/">Hjem</a>
        <!--a v-on:click="closeNav()" href="/#festivalpass">Festivalpass</a>
        <a v-on:click="closeNav()" href="/#praktiskinfo">Praktisk info</a>
        <a v-on:click="closeNav()" href="/bilder">Bilder</a-->
        <a v-on:click="closeNav()" href="/experience">Experience</a>
        <a v-on:click="closeNav()" href="/medarbeider">Medarbeider</a>
        <a v-on:click="closeNav()" href="/om">Om Ufestivalen</a>
        <a v-on:click="closeNav()" href="/om#kontakt">Kontakt</a>
        <a v-on:click="closeNav()" href="/leder">Leder</a>
      </nav>
    </div>
    <div class="hamburger">
      <span v-on:click="openNav()">☰</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "meny",
  methods: {
    openNav: function() {
      document.getElementById("nav").style.height = "100vh";
    },
    closeNav: function() {
      document.getElementById("nav").style.height = "0vh";
    }
  }
};
</script>