<template>
  <div class="footer">
    <p> {{year}} © Ufestivalen</p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year: () => {
      var date = new Date().getFullYear();
      return date;
    }
  }
};
</script>