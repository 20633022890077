<template>
  <div class="bilde">
    <img v-bind:src="bildeAdresse" />
  </div>
</template>

<script>
export default {
  name: "bilde",
  props: ["bilde"],
  computed: {
    bildeAdresse: function() {
      return "./img/banner/" + this.bilde + ".jpg";
    }
  }
};
</script>

<style>

</style>