<template>
  <div class="side">
    <toppbilde bilde="leder" />
    <div class="content">
      <h1>Ufestivalen lederside</h1>
      <p>
        Velkommen til Ufestivalens lederside. På denne siden finner du
        informasjon du som reiseleder, ungdomsleder eller pastor trenger i
        forbindelse med Ufestivalen.
        <br />
        Om du er en av heltene som er med som medarbeider på Ufestivalen vil du
        også finne relevant informasjon på denne siden. Om du skulle ha flere
        spørsmål, ta kontakt med din teamleder.<br />
        For andre henvendelser eller spørsmål kan du ta kontakt med oss på
        ufestivalen[at]pinseung.no
      </p>
      <h2 class="text-left">Påmleding</h2>
      <p>
        All påmelding foregår via checkin. Søk opp “Ufestivalen”. Her vil det
        være mulig å kjøpe festivalpass, overnatting og matpass. For mer
        informasjon og detaljer, se påmeldingen på checkin.
      </p>
      <h2 class="text-left">Mat</h2>
      <p>
        Ved påmelding kan du som deltager og medarbeider bestille matpass. Dette
        inkluderer frokost, lunsj og middag.
      </p>
      <h2 class="text-left">Overnatting</h2>
      <p>
        Ønsker du overnatting på Ufestivalen? Utgangspunktet er at alle
        deltakere sover i telt, men det er også mulig å booke hytte, sove i
        campingvogn eller booke noen tilgjengelige rom. Hedmarktoppen tilbyr
        hytter med 4 eller 7 senger. Disse bestilles gjennom checkin når du
        melder deg på. Det er begrenset antall plasser, så det gjelder å være
        tidlig ute.
      </p>
      <h2 class="text-left">Tidlig oppmøte</h2>
      <p>
        Overnatting på festivalområdet er i utgangspunktet begrenset til selve
        festivalen (torsdag - søndag). Vi vet det er noen som reiser langt, og
        gjerne ønsker å komme dagen før festivalstart og overnatte allerede fra
        onsdagen. Dersom dere skulle ønske det MÅ dere avtale dette med oss på
        forhånd. Ta kontakt på ufestivalen[at]pinseung.no dersom ditt
        ungdomsarbeid ønsker å komme dagen før festivalstart.
      </p>
      <h2 class="text-left">Gruppepåmelding</h2>
      <p>
        Er dere en gruppe på 10 personer eller fler kan dere ta kontakt med oss
        for å få veiledning / hjelp til å gjøre en gruppepåmelding til
        Ufestivalen. Send oss en mail på ufestivalen[at]pinseung.no.
      </p>

      <h2 class="text-left">Promo</h2>
      <p>
        Vi ønsker å gjøre det lett for deg og vise frem Ufestivalen til dine
        ungdommer. Derfor samler vi alt av promomateriell vi publiserer i en
        mappe på Dropbox som du kan laste ned og bruke. Ikke nøl med å ta
        kontakt med oss om du trenger andre filtyper eller materiell.
      </p>

      <knapp
        :url="'https://www.dropbox.com/sh/9q8u6fdz2poaugc/AAB3nvmYoG4f1g2p3EdphQaCa?dl=0'"
        :tittel="'Promoterings materiell'"
      />

      <h2 class="text-left" id="transport">Transport til Hedmarktoppen</h2>
      <p>
        Ufestivalen organiserer ikke selv noe transport for deltakere, men det
        er flere ungdomsarbeid i Norge som reiser til Ufestivalen og setter opp
        felles transport som det er mulig å koble seg på. Det er også mulig å
        kjøre bil opp til Hedmarktoppen og det vil være mulig å parkere på egnet
        område. Dersom du ikke kjører selv, eller tar buss er et annet
        alternativ å ta tog til Hamar sentrum, og rutebuss videre opp til
        Hedmarktoppen.
      </p>
      <p class="text-left">
        <b>Tog:</b>
        <a href="http://vy.no">www.vy.no</a>
        <br />
        <b>Rutebuss i Hamar:</b>
        <a href="http://www.hedmark-trafikk.no/Rutetabeller"
          >www.hedmark-trafikk.no/Rutetabeller</a
        >
      </p>
      <h2 class="text-left">Ledernettverk</h2>
      <p>
        Ufestivalen ønsker å utruste ungdomsledere. Du som er
        ungdomsleder/pastor er derfor hjertelig velkommen til Ufestivalens
        ledernettverk. Her vil det bli inspirerende undervisning, deilig mat og
        mulighet til å bygge viktige relasjoner med andre ungdomsledere.
        Påmelding gjøres når du registrerer deg på checkin.
      </p>
      <h2 class="text-left">Medarberider</h2>
      <p>
        Vi trenger alltid gode medarbeidere. Å være medarbeider er en unik
        mulighet for dine folk til å kunne bli kjent med andre fra hele Norge,
        bruke sine gaver til å bety noe for ungdom fra hele landet. Se
        <a href="/medarbeider">medarbeidersiden</a> for mer informasjon om de
        ulike teamene og ta kontakt med oss på ufestivalen[at]pinseung.no om du
        ønsker å bidra. Vi gjør oppmerksom på at de som ønsker å være med som
        medarbeidere må være minimum 19 år.
      </p>
      <h2 class="text-left">Voiksenkontakt</h2>
      <p>
        På Ufestivalen har vi de siste årene hatt voksenkontakter tilstede på festivalen. Dette ser vi på som en utrolig god ressurs, og ikke minst en trygghet for ungdommene. Vi trenger flere voksenkontakter. Ønsker du, eller vet du om noen som kunne tenke seg dette, ta gjerne kontakt med oss på e-post ufestivalen[at]pinseung.no så finner vi løsninger sammen.
      </p>
    </div>
  </div>
</template>

<script>
import knapp from "../components/Knapp.vue";
import toppbilde from "../components/Toppbilde";

export default {
  name: "Om",
  components: {
    toppbilde,
    knapp,
  },
};
</script>