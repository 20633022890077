<template>
  <div class="side">
    <temabilde />
    <div id="qoute" class="content">
      <h1 class="text-left">Ufestivalen er til for denne ene drømmen;</h1>
      <h2 class="text-left">
        At tenåringer skal få et ekte, nært og personlig møte med Jesus! Vi tror
        på at når ungdommer kommer sammen så vil de få åpnet øynene for hvem
        Jesus virkelig er.
      </h2>
    </div>
    <div>
      <Knapp :url="'https://www.checkin.no/event/19997/ufestivalen-2022'" :tittel="'Bestill festivalpass!'" />
    </div>
    <InstaFeed
      token="IGQVJXc1NqcU03TU5UM1gxMER3cUFPb1Rkdy15Q1BKSlUzS3RrMEhRLTRWMlhwcEFuM3lFd24zQy1jOTRkbUxFRVJRRGpla3NyR1lyMFowWFpwOXNGbHBVRkxfMlVaNEh2Q3RZATWcxelpLZAzhOY29BawZDZD"
      fields="media_url,media_type,caption,permalink"
      container-class="instagram-image-container"
      :mediatypes="['IMAGE', 'VIDEO', 'CAROUSEL_ALBUM']"
      :count="12"
    >
      <template v-slot:loading="props">
        <h1 v-if="props.loading" class="fancy-loading">
          ...
        </h1>
      </template>

      <template v-slot:feeds="props">
        <div>
          <a :href="props.feed.permalink" rel="noopener" target="_blank">
            <div class="instagram-image">
              <img :src="props.feed.media_url" alt="Instagram post" />
              <div :text="props.feed.caption" />
            </div>
          </a>
        </div>
      </template>

      <template v-slot:error="props">
        <div class="fancy-alert">
          {{ props.error }}
        </div>
      </template>
    </InstaFeed>
    <bilde bilde="bykampen" />
    <div id="denstoredrommen" class="content" style="padding-top: 2em;">
      <h1>Den store drømmen</h1>
      <p class="justify">
        Ufestivalen er til for denne ene drømmen om at tenåringer skal få et
        ekte, nært og et personlig møte med Jesus, som vi tror vil forandre
        norge. Vi drømmer om at når ungdommer kommer sammen så vil de få åpnet
        øynene for hvem Jesus er.
      </p>
      <h2>Hensikt</h2>
      <p class="justify">
        Ufestivalen er Pinsvennenes ungdomsfestival, hvor målgruppen er 13-19,
        og ønsket er å se den lokale kirken og de forskjellige ungdomsmiljøene
        inspireres og vokse. Vi ønsker at dette skal være en festival som peke
        på Jesus, gir ungdommer en følelse av tilhørighet, og ett bilde av noe
        større. Vi tror at Ufestivalen kan gi ungdommer ett glimt av hvordan
        fremtiden kan se ut, når flere ungdommer søker kirka og Gud.
      </p>
      <h2>Ufestivalens verdier:</h2>
      <p class="justify">
        1. Vi ønsker å være en festival som først av alt peker på Jesus i alt vi
        gjør. Jesus er grunnen til at vi gjør det vi gjør. Vi ønsker at hver
        eneste ungdom og leder som kommer på Ufestivalen skal få ett møte med
        <span class="increase">Jesus</span> som vil være livsforvandlende. Og vi
        er overbevist at det at hundrevis av ungdommer får et møte med Jesus kan
        være ikke bare forvandler byer, skoler og kirker, men også landet vårt.
      </p>
      <p class="justify">
        2. Vi ønsker at alle som skal komme, enten de kommer alene eller i
        gruppe skal få venner for livet. Vi tror at venner er nøkkelen til mye.
        Det sies at vis meg dine 5 nærmeste venner og jeg kan fortelle deg
        hvordan din fremtid vil bli. Vi tror at Ufestivalen er et sted hvor
        ungdommer får
        <span class="increase">vennskap</span> som varer. Vi ønsker at
        ungdomsarbeidene skal være preget av gode venner som heier frem det
        beste i hverandre, snakker positivt, og lever et liv som peker på Jesus.
      </p>
      <p class="justify">
        3. Vi vil ha det
        <span class="increase">MORRO!</span>
      </p>
    </div>
  </div>
</template>

<script>
import temabilde from "../components/Temabilde";
import bilde from "../components/bilde";
import InstaFeed from "../components/InstaFeed.vue";
import Knapp from '../components/Knapp.vue';

export default {
  name: "Forside",
  components: {
    temabilde,
    bilde,
    InstaFeed,
    Knapp,
  },
};
</script>