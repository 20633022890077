<template>
  <div class="side">
    <toppbilde bilde="experience"/>
    <div class="content">
      <h1 class="text-center">Ufestivalen experience</h1>
      <knapp :element="'taler'" :tittel="'Taler'" />
      <knapp :element="'highlights'" :tittel="'Highlights'" />
      <h1 id="taler" class="text-center">Taler fra ufestivalen</h1>
      <h2 id="thomas" class="text-center">Thomas Neteland, Ufestivalen 2018</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/QkncKX-pXkk"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2 id="sandra" class="text-center">
        Sandra Elén Bjørnø - Ufestivalen 2017
      </h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/N3ZsaFrg5Ug"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2 id="sandra" class="text-center">Dan Blythe - Ufestivalen 2018</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/OLljoW_JDKU"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2 id="alex" class="text-center">Alex Botn - Ufestivalen 2018</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/TmAP4687BM0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>

      <h1 id="highlights" class="text-center">Ufestivalen sessions</h1>
      <h2 class="text-center">Liv Toril Rinding Skjeggestad</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/3KtQ2P-g7qI"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>

      <h1 id="highlights" class="text-center">Highlights fra Ufestivalen</h1>
      <h2 class="text-center">Ufestivalen 2019</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/fObkzDCn5f0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2 class="text-center">Ufestivalen 2018</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/DZDCFTVMJK0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2 class="text-center">Ufestivalen 2017</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/2JzoHLaVbFQ"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2 class="text-center">Ufestivalen 2016</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/uZyL9ScZOk0"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <h2 class="text-center">Ufestivalen 2015</h2>
      <p class="whitePad">
        <iframe
          width="100%"
          height="500"
          src="https://www.youtube-nocookie.com/embed/pX3KkZ5E23Y"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </p>
      <!--End tekst -->
    </div>
  </div>
</template>

<script>
import toppbilde from "../components/Toppbilde";
import knapp from "../components/Knapp";
import Knapp from "../components/Knapp.vue";

export default {
  name: "Om",
  components: {
    toppbilde,
    knapp,
  },
};

Knapp;
</script>