<template>
  <div id="app">
    <div>
      <Meny />
      <router-view> </router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";

import Meny from "./components/Meny";

import Experience from "./pages/Experience";
import Forside from "./pages/Forside";
import Medarbeider from "./pages/Medarbeider";
import Leder from "./pages/Leder";
import Om from "./pages/Om";

import Footer from "./components/Footer";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", component: Forside },
    { path: "/experience", component: Experience },
    { path: "/medarbeider", component: Medarbeider },
    { path: "/leder", component: Leder },
    { path: "/om", component: Om },
  ],
});

export default {
  name: "app",
  router,
  components: {
    Meny,
    Footer,
  },
};
</script>

<style>
</style>
