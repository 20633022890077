<template>
  <div>
    <toppbilde bilde="medarbeider" />
    <div class="content">
      <h1 class="text-center">Medarbeider på Ufestivalen</h1>
      <p class="text-center">
        Vi kunne ikke gjort Ufestivalen uten våre fantastiske medarbeidere!
        Hvert år stiller omtrent 250 enkeltmennesker opp på Hedmarktoppen for å
        legge til rette for, og skape sommerens beste ungdomsfetival. Har du
        lyst til å være med å skape Ufestivalen?
      </p>
      <p class="text-center">
        Finn ditt team under og send oss en mail på:
        informasjon[at]ufestivalen.no
      </p>
      <hr />
      <div class="item2">
        <div class="grid-bilde">
          <img src="/img/medarbeider/aktivitet.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Aktivitetetsteam</h3>
          <p>
            Mottoet til Ufestivalen er «Jesus, venner og moro». Aktivitetsteam
            legger til rette for at deltakerne kan danne vennskap ved å sette
            opp moro aktiviteter gjennom hele festivalen. Er du praktisk anlagt
            og generelt et giret mennesket som ønsker å skape god stemning, er
            aktivitetsteam perfekt for deg.
          </p>
        </div>
      </div>
      <div class="item1">
        <div class="grid-bilde">
          <img src="/img/medarbeider/bykampen.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Bykampen</h3>
          <p>
            Gjennom flere år har bykampen vært et av høydepunktene for mange
            deltakere på Ufestivalen. En sterk rivaliserende konkurranse mellom
            byene med forskjellige øvelser som blir tatt til nye høyder hvert
            år. Er du praktisk anlagt, klar til å ta et løft, gledesspreder og
            løsningsorientert er dette teamet for deg.
          </p>
        </div>
      </div>
      <div class="item2">
        <div class="grid-bilde">
          <img src="/img/medarbeider/estetisk.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Estetisk team</h3>
          <p>
            Mottoet til Ufestivalen er «Jesus, venner og moro». Aktivitetsteam
            legger til rette for at deltakerne kan danne vennskap ved å sette
            opp moro aktiviteter gjennom hele festivalen. Er du praktisk anlagt
            og generelt et giret mennesket som ønsker å skape god stemning, er
            aktivitetsteam perfekt for deg.
          </p>
        </div>
      </div>
      <div class="item1">
        <div class="grid-bilde">
          <img src="/img/medarbeider/matbod.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Matbod</h3>
          <p>
            På matbod team sørger vi for at alle deltakere får kjøpt seg mat og
            drikke. Her vil du få mulighet til å treffe hver enkelt deltaker, og
            slått av en prat. Sentrale oppgaver på dette teamet er å selge og
            forberede mat, og håndtere kunder. På dette teamet vil du oppleve
            god stemning, til tider jobbe i et høyt tempo og godt tjeneste
            miljø.
          </p>
        </div>
      </div>
      <div class="item2">
        <div class="grid-bilde">
          <img src="/img/medarbeider/vakt.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Vakt-team</h3>
          <p>
            Vakt team er for deg som er genuint opptatt av andres sikkerhet, og
            ønsker å være med å skape en trygg atmosfære for deltakerne. På
            vaktteam vil du være med i et felleskap som står på for å passe på
            deltakernes velvære. Dette er et team som jobber tett og skaper et
            godt miljø.
          </p>
        </div>
      </div>
      <div class="item1">
        <div class="grid-bilde">
          <img src="/img/medarbeider/informasjon.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Informasjonsteam</h3>
          <p>
            På informasjons team sørger vi for at alle deltakere og medarbeidere
            får festivalpass, et sted å overnatte, får meldt seg på sine
            favorittaktiviteter og være tilstede for folk som skulle lure på
            noe. Her vil du få en unik mulighet til å treffe masse mennesker,
            samtidig som man får utfordret sin evne til problemløsning. Liker du
            å være sosial, jobbe tett sammen i team og til tider jobbe i et høyt
            tempo, er dette teamet for deg!
          </p>
        </div>
      </div>
      <div class="item2">
        <div class="grid-bilde">
          <img src="/img/medarbeider/miljøteam.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Miljøteam</h3>
          <p>
            Som miljøteam går du rundt i gul t-skjorte og skaper god stemning
            hvor enn du er. Målet vårt er å vise Jesu kjærlighet i praksis. Vi
            prøver alt vi kan for å se de som er alene, gi alle en «high five»
            eller en oppmuntring.
          </p>
          <p>
            I tillegg innebærer oppgaven som miljøteam alt fra å stå i døra og
            heie på alle som går inn på møtet, til å dele ut is på bykampen.
            Miljøteam er synlig og vi er der for å skape et godt og trygt miljø.
            Noe av det viktigste vi gjør på miljøteam er å ha det GØY!!
          </p>
        </div>
      </div>
      <div class="item1">
        <div class="grid-bilde">
          <img src="/img/medarbeider/produksjon.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Produksjonsteam</h3>
          <p>
            Produsentenes oppgave er å ha et overordnet ansvar for forberedelser
            og gjennomføring av alt som skjer av program på scenene. De
            forbereder møteplanene i detaljnivå og informere videre til de
            sceneinvolverte og teknikkteamet slik at alt ser bra ut og flyter
            best mulig.
          </p>
        </div>
      </div>
      <div class="item2">
        <div class="grid-bilde">
          <img src="/img/medarbeider/merch.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Merch</h3>
          <p>
            Merch er et team som jobber sammen for å skape årets «festival
            antrekk». Det å være med på Merch er utrolig spennende, hvor du får
            muligheten utfordre seg selv, slippe kreativiteten løs. Under
            festivalen er du med på å selge og vise frem merchen til
            festivaldeltakerene.
          </p>
        </div>
      </div>
      <div class="item1">
        <div class="grid-bilde">
          <img src="/img/medarbeider/teknikk.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Teknisk team</h3>
          <p>
            Er du interessert i lyd, lys og bilde? Eller bare veldig glad i å
            rigge? Da er teknisk team for deg. Bli med bak kulissene på
            Ufestivalen, og være med på å legge til rette for møter og
            aktiviteter så alle får med seg det som skjer.
          </p>
        </div>
      </div>
      <div class="item2">
        <div class="grid-bilde">
          <img src="/img/medarbeider/media.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Media</h3>
          <p>
            Mediateamet er for deg som digger å holde på med foto, film og
            design! Media har ansvaret for å dokumentere festivalen og det som
            skjer fra dag til dag, samt produsere innholde som vi deler i
            sosialemedier under, og etter festivalen. Bilder og video fra
            festivalen blir også brukt neste år for å promotere festivalen som
            kommer.
          </p>
        </div>
      </div>
      <div class="item1">
        <div class="grid-bilde">
          <img src="/img/medarbeider/rettferd.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>Rettferdighetsprosjekt</h3>
          <p>
            Vi ønsker at ufestivalen skal ha ringvirkninger i hele verden, og
            gjøre det vi kan for at menneske skal ha det bedre. Derfor har vi to
            rettferdighets prosjekt hvert år. Nemlig «sitt med oss» og “i want
            to graduate” i Mosambik.
          </p>
          <p>
            Sitt med oss er en inkluderings kampanje som ønsker å vise ungdom
            hvor enkelt det faktisk er å få mennesker til å føle seg sett og
            ønsket. Flere titusener av barn og unge i Norge i dag, sier at de
            blir mobbet. Vi ønsker å gjøre en forskjell. Bli med på dette teamet
            om du har passion for misjon
          </p>
        </div>
      </div>
      <div class="item2">
        <div class="grid-bilde">
          <img src="/img/medarbeider/bost.jpg" />
        </div>
        <div class="grid-tekst">
          <h3>BOST</h3>
          <p>
            Bønn og samtaleteamet er et team der du får komme tett på ungdommene
            under festivalen. Du får mulighet og privilegium å be og samtale med
            ungdommer som ønsker forbønn og noen å prate med. En viktig del av
            tjenesten er å delta på undervisning ved oppstart, og være med i
            fellessamlinger med teamet i bønn og lovsang på festivalen.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toppbilde from "../components/Toppbilde";

export default {
  name: "medarbeider",
  components: {
    toppbilde,
  },
};
</script>

<style>
</style>