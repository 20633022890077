<template>
  <div class="side">
    <toppbilde bilde="om" />
    <div class="content">
      <h1 id="kontakt" class="text-center">Kontakt Ufestivalen</h1>
      <p>
        Har du spørsmål om Ufestivalen? Ikke nøl med å ta kontakt med oss.
        <br />Send oss en mail på
        <b>ufestivalen[at]pinseung.no</b>
      </p>
      <h1 id="om" class="text-center">Ufestivalen</h1>
      <br />
      <h3>Den store drømmen</h3>
      <p class="justify">
        Ufestivalen er til for denne ene drømmen om at tenåringer skal få et
        ekte, nært og et personlig møte med Jesus, som vi tror vil forandre
        norge. Vi drømmer om at når ungdommer kommer sammen så vil de få åpnet
        øynene for hvem Jesus er.
      </p>
      <h3>Hensikt</h3>
      <p class="justify">
        Ufestivalen er Pinsvennenes ungdomsfestival, hvor målgruppen er 13-19,
        og ønsket er å se den lokale kirken og de forskjellige ungdomsmiljøene
        inspireres og vokse. Vi ønsker at dette skal være en festival som peker
        på Jesus, gir ungdommer en følelse av tilhørighet, og ett bilde av noe
        større. Vi tror at Ufestivalen kan gi ungdommer ett glimt av hvordan
        fremtiden kan se ut, når flere ungdommer søker kirka og Gud.
      </p>
      <br />
      <h1>Ufestivalens verdier</h1>
      <p class="justify">
        1. Vi ønsker å være en festival som først av alt peker på Jesus i alt vi
        gjør. Jesus er grunnen til at vi gjør det vi gjør. Vi ønsker at hver
        eneste ungdom og leder som kommer på Ufestivalen skal få ett møte med
        <span class="increase">Jesus</span> som vil være livsforvandlende. Og vi
        er overbevist at det at hundrevis av ungdommer får et møte med Jesus kan
        være ikke bare forvandler byer, skoler og kirker, men også landet vårt.
      </p>
      <p class="justify">
        2. Vi ønsker at alle som skal komme, enten de kommer alene eller i
        gruppe skal få venner for livet. Vi tror at venner er nøkkelen til mye.
        Det sies at vis meg dine 5 nærmeste venner og jeg kan fortelle deg
        hvordan din fremtid vil bli. Vi tror at Ufestivalen er et sted hvor
        ungdommer får <span class="increase">vennskap</span> som varer. Vi
        ønsker at ungdomsarbeidene skal være preget av gode venner som heier
        frem det beste i hverandre, snakker positivt, og lever et liv som peker
        på Jesus.
      </p>
      <p class="justify">
        3. Vi vil ha det <span class="increase">MORRO!</span>
      </p>
    </div>
  </div>
</template>

<script>
import toppbilde from "../components/Toppbilde";

export default {
  name: "Om",
  components: {
    toppbilde,
  },
};
</script>