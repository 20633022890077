<template>
  <span class="content-center">
    <button v-on:click="click(url, element)">{{ tittel }}</button>
  </span>
</template>

<script>
export default {
  name: "knapp",
  props: {
      url: {
          type: String,
          default: undefined
      },
      element: {
        type: String,
        default: undefined
      },
      tittel: {
          type: String,
          default: "Klikk her"
      }
  },
  methods: {
    click: function (url, element) {
      if (url != undefined){
        this.goToUrl(url);
      }
      else if (element != undefined) {
        this.goToElement(element);
      }
    },
    goToUrl: function (value) {
      window.open(
        value,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    goToElement: function (value) {
      var elmntToView = document.getElementById(value);
      elmntToView.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
  },
};
</script>