import Vue from 'vue'
import App from './App.vue'

import smoothscroll from 'smoothscroll-polyfill';
require('./assets/normalize.css')
require('./assets/css.css')

// kick off the polyfill!
smoothscroll.polyfill();

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')